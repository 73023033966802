<template>
  <CContainer fluid class="mainTableContainer">
    <CRow alignVertical="center" alignHorizontal="center">
      <CCol lg="12" class="mainTableColumn">
        <CDataTable
          :noItemsView="{
            noResults: 'no items found',
            noItems: 'no items',
          }"
          :hover="true"
          :striped="true"
          :items-per-page="20"
          columnFilter
          sorter
          pagination
          :items="getUserListData"
          :clickableRows="true"
          :loading="loading"
          :fields="fields"
          size="sm"
          :tableFilterValue.sync="filter"
          @row-clicked="openUserDetail"
        >
          <template #status="{ item }">
            <td>
              <div v-if="item.status === UserStatusEnums.Verified">
                <ShieldCheck :size="20" title="Verified" />
              </div>
              <div v-if="item.status === UserStatusEnums.NoVerified">
                <ShieldOff
                  :size="20"
                  title="Click for verify"
                  @click="verifyUser(item.id)"
                />
              </div>
            </td>
          </template>
          <template #tariff_id="{ item }">
            <td>{{ nameTariff(item.tariff_id) }}</td>
          </template>
          <template #position="{ item }">
            <td>
              <LoginVariant
                :size="20"
                v-if="UserStatusEnums.Verified === item.status"
                @click.stop="loginAs(item.id)"
              ></LoginVariant>
              <Pencil :size="20" @click.stop="changeItem(item)"></Pencil>
              <Delete
                :size="20"
                @click.stop="showDeleteModal(item.id)"
              ></Delete>
              <Lock
                :size="20"
                @click.stop="toggleBlockUser(item.id)"
                v-if="item.is_blocked"
              ></Lock>
              <LockOpen
                :size="20"
                @click.stop="toggleBlockUser(item.id)"
                v-else
              ></LockOpen>
            </td>
          </template>
        </CDataTable>
      </CCol>
    </CRow>
    <UserChangeModal
      :show="show"
      :item="item"
      @close="closeModal"
      @accept="editItem"
    />
    <DeleteConfirmModal
      :show="showDelete"
      @close="hideDelete"
      @accept="deleteItem"
    />
  </CContainer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { CDataTable, CRow, CCol, CContainer } from "@coreui/vue/src";
import Delete from "vue-material-design-icons/Delete.vue";
import LoginVariant from "vue-material-design-icons/LoginVariant.vue";
import ShieldCheck from "vue-material-design-icons/ShieldCheck.vue";
import ShieldOff from "vue-material-design-icons/ShieldOff.vue";
import Pencil from "vue-material-design-icons/Pencil.vue";
import UserChangeModal from "@/components/modals/UserChangeModal";
import { UserStatusEnums } from "@/enums/UserStatusEnums";
import DeleteConfirmModal from "@/components/modals/DeleteConfirmModal";
import Lock from "vue-material-design-icons/Lock.vue";
import LockOpen from "vue-material-design-icons/LockOpen.vue";
export default {
  name: "UserListComponent",
  data() {
    return {
      item: {},
      UserStatusEnums: UserStatusEnums,
      loading: false,
      fields: [
        { label: "ID", key: "id", sorter: true, filter: true },
        { label: "Name", key: "name", sorter: true, filter: true },
        { label: "Email", key: "email", sorter: true, filter: true },
        { label: "Date register", key: "email_verified_at", sorter: true, filter: true },
        { label: "Phone", key: "phone", sorter: true, filter: true },
        { label: "Verified", key: "status" },
        { label: "Options", key: "position", sorter: false, filter: false },
      ],
      filter: "",
      show: false,
      showDelete: false,
      deleteItemID: "",
    };
  },
  components: {
    UserChangeModal,
    CDataTable,
    CContainer,
    CRow,
    CCol,
    Delete,
    LoginVariant,
    Pencil,
    DeleteConfirmModal,
    ShieldCheck,
    ShieldOff,
    Lock,
    LockOpen,
  },
  methods: {
    ...mapActions("userModule", [
      "getUserList",
      "deleteUser",
      "loginAsUser",
      "editUser",
      "verifyUser",
      "toggleBlockUser",
    ]),
    async deleteItem() {
      await this.deleteUser(this.deleteItemID);
      this.deleteItemID = "";
      this.showDelete = false;
    },
    openUserDetail(user) {
      this.$router.push("/users/" + user.id);
    },
    showDeleteModal(id) {
      this.showDelete = true;
      this.deleteItemID = id;
    },
    hideDelete() {
      this.showDelete = false;
      this.deleteItemID = "";
    },
    async loginAs(id) {
      await this.loginAsUser(id);
    },
    closeModal() {
      this.show = false;
      this.item = {};
    },
    async editItem() {
      let req = {
        id: this.item.id,
        user: this.item,
      };
      await this.editUser(req);
      this.showEdit = false;
    },
    changeItem(item) {
      this.item = item;
      this.show = true;
    },
  },
  computed: {
    ...mapGetters("userModule", ["getUserListData"]),
  },
  async mounted() {
    await this.getUserList();
  },
};
</script>

<style scoped lang="scss"></style>
