var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CContainer',{staticClass:"mainTableContainer",attrs:{"fluid":""}},[_c('CRow',{attrs:{"alignVertical":"center","alignHorizontal":"center"}},[_c('CCol',{staticClass:"mainTableColumn",attrs:{"lg":"12"}},[_c('CDataTable',{attrs:{"noItemsView":{
          noResults: 'no items found',
          noItems: 'no items',
        },"hover":true,"striped":true,"items-per-page":20,"columnFilter":"","sorter":"","pagination":"","items":_vm.getUserListData,"clickableRows":true,"loading":_vm.loading,"fields":_vm.fields,"size":"sm","tableFilterValue":_vm.filter},on:{"update:tableFilterValue":function($event){_vm.filter=$event},"update:table-filter-value":function($event){_vm.filter=$event},"row-clicked":_vm.openUserDetail},scopedSlots:_vm._u([{key:"status",fn:function(ref){
        var item = ref.item;
return [_c('td',[(item.status === _vm.UserStatusEnums.Verified)?_c('div',[_c('ShieldCheck',{attrs:{"size":20,"title":"Verified"}})],1):_vm._e(),(item.status === _vm.UserStatusEnums.NoVerified)?_c('div',[_c('ShieldOff',{attrs:{"size":20,"title":"Click for verify"},on:{"click":function($event){return _vm.verifyUser(item.id)}}})],1):_vm._e()])]}},{key:"tariff_id",fn:function(ref){
        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.nameTariff(item.tariff_id)))])]}},{key:"position",fn:function(ref){
        var item = ref.item;
return [_c('td',[(_vm.UserStatusEnums.Verified === item.status)?_c('LoginVariant',{attrs:{"size":20},on:{"click":function($event){$event.stopPropagation();return _vm.loginAs(item.id)}}}):_vm._e(),_c('Pencil',{attrs:{"size":20},on:{"click":function($event){$event.stopPropagation();return _vm.changeItem(item)}}}),_c('Delete',{attrs:{"size":20},on:{"click":function($event){$event.stopPropagation();return _vm.showDeleteModal(item.id)}}}),(item.is_blocked)?_c('Lock',{attrs:{"size":20},on:{"click":function($event){$event.stopPropagation();return _vm.toggleBlockUser(item.id)}}}):_c('LockOpen',{attrs:{"size":20},on:{"click":function($event){$event.stopPropagation();return _vm.toggleBlockUser(item.id)}}})],1)]}}])})],1)],1),_c('UserChangeModal',{attrs:{"show":_vm.show,"item":_vm.item},on:{"close":_vm.closeModal,"accept":_vm.editItem}}),_c('DeleteConfirmModal',{attrs:{"show":_vm.showDelete},on:{"close":_vm.hideDelete,"accept":_vm.deleteItem}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }